import AttributeType from "src/constants/AttributeType";
import { IMAGE_ALT } from "../constants";

export function renderTab(tabs, selectedTab) {
  return tabs[selectedTab];
}

export function roundStringPrice(string) {
  return Number.parseFloat(string)?.toFixed(2);
}

export function mapAttributes(productAttributes) {
  const filteredAttributes = productAttributes.filter(x => 
    !x?.deleted &&
    !x?.attribute_value?.deleted &&
    !x?.attribute_value?.attribute?.deleted
  );
  const uniqueAttributes = _.uniq(filteredAttributes?.map(x => x.attribute_value?.attribute?.name));
  return uniqueAttributes.map(x => ({
    name: x,
    values: filteredAttributes?.filter(a => a.attribute_value?.attribute?.name === x)?.map(a => a?.attribute_value),
    type: filteredAttributes?.find(a => a.attribute_value?.attribute?.name === x)?.attribute_value?.attribute?.attribute_type,
    colorNames: filteredAttributes?.filter(a => a.attribute_value?.attribute?.name === x)?.map(a => a?.attribute_value?.customer_color_name),
  }));
}

export function mapFilters(productFilters = []) {
  const uniqueFilters = _.uniq(productFilters.map(x => x.attributeValue?.attribute?.name));
  const uniqueAttributeValues = _.uniqBy(productFilters.map(x => x.attributeValue), 'id');

  return uniqueFilters.map(x => ({
    name: x,
    values: uniqueAttributeValues.filter(a => a?.attribute?.name === x)?.map(a => a).sort((a, b) => {
      const isColorType = a.attribute?.attributeType !== AttributeType.STRING;
      if(isColorType)
        return a.customerColorName?.toLowerCase() < b?.customerColorName?.toLowerCase() ? -1 : 1;
      return a.attribute?.name?.toLowerCase() < b?.attribute?.name?.toLowerCase() ? -1 : 1;
    }),
    type: productFilters.find(a => a.attributeValue?.attribute?.name === x)?.attributeValue?.attribute?.attributeType,
    colorNames: productFilters?.filter(a => a.attributeValue?.attribute?.name === x)?.map(a => a?.attributeValue?.customerColorName),
  }))  
}

export function mapProducts(productFilters = []) {
  const uniqueProducts = _.uniqBy(productFilters.map(x => x.product), 'id');

  return uniqueProducts.map(x => ({
    ...x,
    attributeValues: 
    productFilters.reduce((acc, a) => 
      a.product.id == x.id
      ? [...acc, a.attributeValue.id]
      : acc
    , [])
  }))
}

export function mapCategories(productFilters = [], categories) {
  const uniqueCategories = _.uniqBy(productFilters.map(x => x.product.category), 'id');

  return uniqueCategories.map(x => {
    const brands = productFilters.reduce((acc, a) =>
      a.product.category.id === x.id && !a.product.brand.deleted
      ? [...acc, { id: a.product.brand.id, name: a.product.brand.name }]
      : acc
    , []);
    const uniqueBrands = _.uniqBy(brands, 'id');
    const categoryImage = x.categoryImage;

  return {
    ...x,
    attributeValues: productFilters.reduce((acc, a) =>
    a.product.category.id == x.id
    ? [...acc, a.attributeValue.id]
    : acc
  , []),
    min_price: categories.find(c => c.id === x.id)?.min_price,
    max_price: categories.find(c => c.id === x.id)?.max_price,
    brands: uniqueBrands,
    image: `/uploads/images/category_image/1/${categoryImage.id}/${categoryImage.name}.${categoryImage.type}`
  }});
}

export function mapBrandsFromFilters(filters) {
  return _.uniqBy(filters?.map(x => ({ id: x?.product?.brand?.id, name: x?.product?.brand?.name })), 'id');
}

export function mapNameToUrl(name) {
  return name?.replaceAll(' ', '-').replaceAll('%', '')?.toLowerCase();
}

export function getImageAlt() {
  return IMAGE_ALT[Math.floor(Math.random()*6)];
}