import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Form as FinalForm } from 'react-final-form';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import TextInput from 'src/redesign/components/Form/TextInput';
import SubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Image from 'src/redesign/components/Image';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import StepNumber from 'src/redesign/components/StepNumber';
import { Logo50 } from 'src/redesign/components/Logo';
import { getCurrentUserAction, setStoreUrl } from 'src/services/UserService';
import { getImageAlt } from 'src/redesign/helpers';
import * as Validators from 'src/redesign/util/validators';
import './style.scss';
import colors from 'src/scss/_colors.scss';

const ShopifyConnectStore = ({ history }) => {
  const { openSnackBar } = useSnackbar();
  const [userStoreUrl, setUserStoreUrl] = useState(null);

  const getUserData = async () => {
    try {
      const { data: { user: { customer } } }  =  await getCurrentUserAction();
      setUserStoreUrl(customer?.store_url)
      if (customer?.shopify_access_token) {
        history.push('/stores');
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
      openSnackBar('Failed to refresh user data', false);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const saveStoreUrl = async ({ url }) => {
    const cleanUrl = (url.charAt(url.length - 1) === '/' ? url.slice(0, -1) : url).replace('https://', '').replace('http://', '');
    try {
      await setStoreUrl({ storeUrl: cleanUrl });
      setUserStoreUrl(cleanUrl);
      openSnackBar('Successfully saved');
    } catch (error) {
      console.error('Error saving store URL:', error);
      openSnackBar('Failed to save store URL', false);
    }
  };

  return (
    <ApplicationPage selectedTab="stores">
      <ApplicationPageContent title="Sales Channels">
        <div className="setup-instructions-shopify">
          {userStoreUrl && <div className="vertical-line" />}
          <Image alt={getImageAlt()} src="/images/shopify.png" className="image" />
          <div className="step">
            <StepNumber number={1} completed={!!userStoreUrl} className="first-step" />
            <div className="step-content">
              <div className="title">
                <div className="step-text">Step 1</div>
                <div className="logo">
                  <Logo50 />
                  <div className="logo-text">Surge Print</div>
                </div>
              </div>
              <div className='form'>
                <div className='text'>
                  Add your Shopify store URL here:
                </div>
                <div className='store-url-input'>
                  <FinalForm onSubmit={saveStoreUrl} initialValues={{ url: userStoreUrl }} render={({ handleSubmit, valid, submitting, values }) => (
                    <form onSubmit={handleSubmit} className='form-content'>
                      <TextInput
                        name='url'
                        placeholder='Store URL'
                        validate={Validators.required(' ')}
                        style={{ 
                          width: 
                            values.url?.length > 20
                            ? `${(values.url.lengths+ 1) * 7.5}px`
                            : '176px'
                        }}
                      />
                      <SubmitButton text='Save' type='submit' width={74} disabled={!valid || submitting} loading={submitting} />
                    </form>
                  )}
                  />
                </div>
              </div>
              <div className="description">
                If there is any problem with connecting your store, make sure you have added the
                right format of the store URL - <b>example.myshopify.com</b> or <b>example.com</b>
                <br />
                <br />
                If you're being redirected here after you've added Shopify store URL and completed
                Step 2 please check again and update your Shopify store field with the correct URL.
                <br />
                If you need further assistance, please contact us and our lovely support team will
                help you immediately -{' '}
                <a className="description-link" href="mailto:support@surgeprint.com">
                  support@surgeprint.com
                </a>
              </div>
            </div>
          </div>
          {userStoreUrl != null && 
            <div>
              <div className="step" style={{ marginTop: '40px', alignItems: 'center' }}>
                <StepNumber number={2} completed={false} />
                <div className="step-content">
                  <div className="title" style={{ alignItems: 'center' }}>
                    <div className="step-text">Step 2</div>
                    <Image alt={getImageAlt()} src="/images/shopify.png" className="image" />
                  </div>
                </div>
              </div>
              <div className="get-surgeprint">
                Click the Get the SurgePrint:
                <a href="https://apps.shopify.com/surge-print">
                  <SubmitButton
                    text="GET THE SURGEPRINT"
                    width={202}
                    className="get-surgeprint-button"
                    style={{
                      background: 'linear-gradient(270deg, #020C6A 19.24%, #00F2E2 99.22%)',
                      color: colors.white,
                      border: 'none',
                    }}
                  />
                </a>
              </div>
            </div>}
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  );
};

export default withRouter(ShopifyConnectStore);