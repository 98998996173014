import React from 'react';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import Image from 'src/redesign/components/Image';
import { setFromPathLocalStorage } from 'src/base/OAuth';
import { getPrimaryMethod } from 'src/services/BillingInformation';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PlaceAnOrderPage = ({ history }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasBillingInformation, setHasBillingInformation] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadPaymentInformation();
  }, []);

  const loadPaymentInformation = async () => {
    setIsLoading(true);
    try {
      const { data: { billing_address }} = await getPrimaryMethod();

      setHasBillingInformation(billing_address ? true : false);
    } catch(error) {
      console.error(error);
      setHasBillingInformation(false);
      openSnackBar('Failed to load payment information', false);
    } finally {
      setIsLoading(false);
    }
  }

  const goTo = path => {
    setFromPathLocalStorage('placeAnOrder');
    history.push(path);
  }

  const cards = [
    {
      image: '/images/new-product-3.png',
      buttonText: 'Create a New Product',
      onClick: () => goTo('/product-catalog-in-app/all')
    },
    {
      image: '/images/Frame-12.png',
      buttonText: 'Saved Products',
      onClick: () => goTo('/product-templates/storage', {"fromPage": "placeAnOrder"})
    },
  ]

  return (
    <ApplicationPage selectedTab='place-an-order'>
      <ApplicationPageContent title='Place on Order'>
        {isLoading
        ? <Loader width={200} height={200} color={colors.primary} />
        :
          hasBillingInformation
          ? 
            (
              <div className='place-an-order'>
                {cards.map(({ image, buttonText, onClick }, i) => (
                  <div className='card' onClick={onClick} key={uuid()}>
                    <Image alt={getImageAlt()} src={image} className='image' />
                    <div className='button'>
                      {buttonText}
                    </div>
                  </div>
                ))}
              </div>
            )
            : <div>Please go to the <a onClick={() => history.push('/settings/billing-information')} style={{ color: colors.secondary, cursor: 'pointer' }}>Settings tab</a> and add your company and credit card info in order to create Orders.</div>}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(PlaceAnOrderPage);