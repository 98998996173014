import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';

import './style.scss';

const Attribute = ({ id, name, isSelected, onClick, disabled }) => (
  <div className={classNames('attribute-value', {'attribute-selected': isSelected(id), 'attribute-disabled': disabled})} key={id} onClick={disabled ? null : () => onClick(id)}>
    {name}
  </div>
);

Attribute.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  isSelected: func.isRequired,
  onClick: func.isRequired,
  disabled: bool
}

export default Attribute;