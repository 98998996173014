import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';
import { Form as FinalForm } from 'react-final-form';

import Loader from 'src/redesign/components/Loader';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import TextInput from 'src/redesign/components/Form/TextInput';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import Checkbox from 'src/redesign/components/Checkbox';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Image from 'src/redesign/components/Image';
import { LogoNavbarExpanded } from 'src/redesign/components/Logo';
import { getCountryList } from 'src/services/CountryService';
import { getImageAlt } from 'src/redesign/helpers';
import { registration } from 'src/services/UserService';
import { clearDataForRegistrationLocalStorage, login } from 'src/base/OAuth';
import * as Validators from 'src/redesign/util/validators';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const RegistrationPage = ({ history }) => {
  const [loading, setLoading] = React.useState(false);
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [isTermsChecked, setIsTermsChecked] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);
  
  const load = async () => {
    setLoading(true);
    try {
      const { data: { countries }} = await getCountryList();

      setCountries(countries);
      setCountryOptions(countries.map(x => ({ key: x.id, text: x.name })));
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to load countries', false);
    } finally {
      setLoading(false);
    }
  }

  const register = async values => {
    console.log(values);
    try {
      const response = await registration({
        ...values,
        country: countries.find(x => x.id === Number.parseInt(values.country))?.id,
        agree: true,
        agreeColorRed: false,
        shopify: false,
        etsy: false,
        wooCommerce: false,
        magento: false,
        amazon: false,
        ebay: false,
        bigCommerce: false,
        wayfair: false,
        wix: false,
        squarespace: false,
        physicalRetail: false,
        other: false
      });
  
      if(!response.ok) {
        openSnackBar('Email already exists!', false);
        return;
      }
  
      clearDataForRegistrationLocalStorage();
      loginAfterRegister(values.email, values.password);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to register!', false);
    }
  }

  const loginAfterRegister = async (email, password) => {
    await login(email, password);
    history.push('/stores');
  }
  
  return (
    <>
      <Helmet>
        <title>Create an account | Surge Print</title>
      </Helmet>
      <div className='registration-page'>
        <div className='image-section'>
          <div className='title'>
            Start Selling
          </div>
          <Image alt={getImageAlt()} className='image' src='/images/landing-image.png' />
        </div>
        <div className='create-account-section'>
          <div className='signup-logo-container'>
            <LogoNavbarExpanded />
          </div>
          {loading ? (
            <Loader width={200} height={200} color={colors.primary}/>
          ) : (
            <FinalForm
              onSubmit={register}
              initialValues={{}}
              render={({ handleSubmit, valid, submitting }) => (
              <form className='signup-form' onSubmit={handleSubmit}>
                <h1 className='signup-title'>
                  Create an account
                </h1>
                <div className='signup-form-row'>
                  <TextInput containerClassName='input-container' className='input-name-field' name='firstName' label='First Name' placeholder='Enter your first name' validate={Validators.required('First name is required')}/>
                  <TextInput containerClassName='input-container' className='input-name-field' name='lastName' label='Last Name' placeholder='Enter your last name' validate={Validators.required('Last name is required')}/>
                </div>
                <TextInput containerClassName='input-container' name='email' label='Email' placeholder='Enter your email' validate={Validators.composeValidators(Validators.required('Email is required'), Validators.emailFormatValid('Email format is not valid'))}/> 
                <TextInput containerClassName='input-container' name='password' label='Password' placeholder='Create a password' type='password' validate={Validators.required('Password is required')}/>
                <SelectInput
                  className='select-container'
                  name="country"
                  label="Country"
                  options={countryOptions}
                  placeholder="Choose your country"
                  validate={Validators.required('Country is required')}
                />
                <TextInput containerClassName='input-container' name='companyName' label='Company' placeholder='Enter company name' validate={Validators.required('Company name is required')}/>
                <TextInput containerClassName='input-container' name='city' label='City' placeholder='Enter your city name' validate={Validators.required('City is required')}/>
                <TextInput containerClassName='input-container' name='phoneNumber' label='Phone number' placeholder='Enter your phone number' type='number' validate={Validators.required('Phone number is required')}/>
                <TextInput containerClassName='input-container' name='storeUrl' label='Store' placeholder='Enter your store URL' validate={Validators.required('Store is required')}/>
                <SubmitPrimaryButton className='signup-submit-button' type='submit' text='Sign up' disabled={!valid || submitting || !isTermsChecked} loading={submitting}/>
                <div className='signup-form-row'>
                  <Checkbox value={isTermsChecked} checked={isTermsChecked} onChange={() => setIsTermsChecked(prev => !prev)} name='agree' />
                  <div className='agree-text'>
                    I agree to Surge Print <a href="/terms-of-service" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </form>
              )}
            />
          )}
        </div>
      </div>
    </>
  )
};

export default withRouter(RegistrationPage);