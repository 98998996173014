import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import UserType from '../constants/UserType';
import {getUserFromLocalStorage} from "../base/OAuth";
import {setFromPathLocalStorage} from "../base/OAuth";
import {getPrimaryMethod} from "../services/BillingInformation";
class PlaceAnOrder extends Page {

    constructor(props) {
        super(props);

        this.state = {
            userType: getUserFromLocalStorage().user_type,
            hasBillingInformation: false
        }
    }

    goTo(path){
        setFromPathLocalStorage("placeAnOrder");
        this.props.history.push(path);
    }

    componentDidMount() {
        this.getBillingInformation();
    }

    getBillingInformation() {
        getPrimaryMethod().then(response => {
            if (!response.ok) {
                return;
            }

            const hasBillingInformation = response.data.billing_address ? true : false;

            this.setState({
                ...this.state,
                hasBillingInformation: hasBillingInformation
            })
        });
    }

    render() {

        return (
            <div id="place-an-order">
                {
                    !this.state.hasBillingInformation &&
                    <div className="export-header"> Please go to the <span className="btn-light" onClick={() => {this.props.history.push('/settings') }}>Settings tab</span> and add your company and credit card info in order to create Orders.</div>
                }
                {
                    this.state.hasBillingInformation &&
                    this.getUser().user_type === UserType.CUSTOMER &&
                    <div className="new-product" onClick={() => this.goTo("/product-catalog")}>
                        <div className="header">Create a New Product</div>
                        <div className="image"></div>
                    </div>
                }
                {
                    this.state.hasBillingInformation &&
                    <div className="saved-products" onClick={ () => this.goTo("/product-templates", {"fromPage": "placeAnOrder"})}>
                        <div className="header">Saved Products</div>
                        <div className="image"></div>
                    </div>
                }
                {
                    this.state.hasBillingInformation &&
                    this.state.userType == UserType.CUSTOMER &&
                    <div className="draft-orders" onClick={ () => this.props.history.push("/drafts")}>
                        <div className="header">Draft Orders</div>
                    </div>
                }
            </div>
            );
        }  
    
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaceAnOrder)));