import React from 'react';
import { v4 as uuid } from 'uuid';
import Loader from 'src/redesign/components/Loader';
import PrimaryButton from 'src/redesign/components/Buttons/Primary';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getProductAttributes, getShippingPricingProduct } from 'src/services/admin/ProductService';
import { mapAttributes, roundStringPrice } from 'src/redesign/helpers';
import AttributeType from 'src/constants/AttributeType';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductDetails = ({ id, price, setDetailsVisible, onStartSellingClick }) => {
  const [loading, setLoading] = React.useState(false);

  const [attributes, setAttributes] = React.useState([]);
  
  const [shipping, setShipping] = React.useState(0);


  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = async () => {
    setLoading(true);
    try {
      const { data: attributes } = await getProductAttributes(id);

      const { data: shipping } = await getShippingPricingProduct(id);
      setShipping(shipping?.us_standard_first);

      const mappedAttributes = mapAttributes(attributes);

      setAttributes(mappedAttributes);
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to load product details!', false);
    } finally {
      setLoading(false);
    }
  }

  return <div className='product-details'>
    <div className='product-details-header'>
      <h2 className='product-details-header-title'>
        Surge Print
      </h2>
      <div className='product-details-header-buttons'>
        <SecondaryButton text="More details" onClick={setDetailsVisible}/>
        <PrimaryButton text="Start designing" onClick={onStartSellingClick}/>
      </div>
    </div>
    {loading ? (
      <Loader
        width={100}
        height={200}
        color={colors.primary}
      />
    ) : (
      <div className='product-details-content'>
        <div className='product-details-cell-container'>
          <div className='product-details-cell-title'>
            Shipped From
          </div>
          <div className='product-details-cell-value-important'>
            United States
          </div>
        </div>
        
        <div className='product-details-cell-container'>
          <div className='product-details-cell-title'>
            Price
          </div>
          <div className='product-details-cell-value-important'>
            From <span className='product-details-cell-value-price'>USD {roundStringPrice(price)}</span>
          </div>
        </div>

        <div className='product-details-cell-container'>
          <div className='product-details-cell-title'>
            Shipping
          </div>
          <div className='product-details-cell-value-important'>
            From <span className='product-details-cell-value-price'>USD {shipping}</span>
          </div>
        </div>
          <div className='product-details-cell-container'>
          <div className='product-details-cell-title'>
            Avg. production time
          </div>
          <div className='product-details-cell-value'>
            1.63 business days
          </div>
        </div>

        {attributes.map(x => (
          <div key={uuid()} className='product-details-cell-container'>
            <div className='product-details-cell-title'>
              {x?.name}
            </div>
              {x.type === AttributeType.STRING
              ? (
                <div className='product-details-cell-value'>
                  {x.values.map(x => x.name).join(', ')}
                </div>
              ) : (
                <div className='product-details-cell-value-color-container'>
                  {x.values.map(v => (
                    <CustomTooltip 
                      key={uuid()}
                      text={v.customer_color_name}                    
                    >
                      <div className='product-details-cell-value-color' style={{ backgroundColor: v.name }}/>
                    </CustomTooltip>
                  ))}
                </div>
              )}
          </div>
        ))}
      </div>
    )}
  </div>
}

export default ProductDetails;